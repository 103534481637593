import request from "@/utils/request";

export function fetchAccounts() {
  return request({
    url: "/accounts/account-payments",
    method: "get",
  });
}

export function fetchAccountContact() {
  return request({
    url: "/accounts/account-contacts",
    method: "get",
  });
}

export function createAccounts(data) {
  return request({
    url: "/accounts",
    method: "post",
    data,
  });
}
export function updateAccounts(id, data) {
  return request({
    url: `/accounts/${id}`,
    method: "put",
    data,
  });
}
export function updateStatus(numero) {
  return request({
    url: `/accounts/change-status/${numero}`,
    method: "put",
  });
}
export function generateAccount() {
  return request({
    url: "/accounts/generate-account-number",
    method: "get",
  });
}

export function fetchAllAccounts(query) {
  return request({
    url: "/accounts/fetch",
    method: "get",
    params: query,
  });
}

export function fetchBalances() {
  return request({
    url: "/accounts/balance",
    method: "get",
  });
}

// operations

export function fetchAllOperation(query) {
  return request({
    url: "/operations/fetch",
    method: "get",
    params: query,
  });
}

export function fetchStastic(query) {
  return request({
    url: "/operations/statistic",
    method: "get",
    params: query,
  });
}
export function newTransaction(data) {
  return request({
    url: "/operations/new-transaction",
    method: "post",
    data,
  });
}

export function transactionContact(data) {
  return request({
    url: "/operations/contact-transaction",
    method: "post",
    data,
  });
}

export function transfertC2C(data) {
  return request({
    url: "/operations/transfert",
    method: "post",
    data,
  });
}
