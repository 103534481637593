<template>
  <div class="flex flex-col font-Inter px-10">
      <Loading v-if="loadingFull" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            reçu paiement N° REÇU : {{ paymentNo }}
          </h2>
        </div>
        <div>
          <el-button icon="el-icon-save" @click="handleBackPage" plain
            >Annuler</el-button
          >
          <el-button
            :disabled="iscompleted"
            type="primary"
            icon="el-icon-save"
            :loading="loading"
            @click="createData('paymentForm')"
            >Enregistrer</el-button
          >
        </div>
      </div>
    </div>
    <div class="mb-10">
      <div class="flex flex-col mx-auto bg-white p-5 shadow">
        <el-form
          :model="payment"
          :rules="rules"
          ref="paymentForm"
          :label-position="labelPosition"
        >
          <div class="flex justify-between items-start">
            <div class="flex flex-col w-1/2">
              <search-customer
                @customer="handleCustomer"
                @search-customer="handleSearchCustomer"
                :customerInfo="customerInfo"
                :customers="customers"
              />
            </div>

            <div class="flex flex-col justify-end items-end w-1/2">
              <div
                class="text-3xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncatemt-2 my-2"
              >
                Montant : {{ totalInvoicePayment | moneyFilter }} {{user.currency_code}}
              </div>
              <el-form-item label="Date" prop="payment_date">
                <el-date-picker
                  v-model="payment.payment_date"
                  type="date"
                  placeholder="jj-mm-aaaa"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Reference">
                <el-input v-model="payment.reference_no"> </el-input>
              </el-form-item>
              <div class="my-5 text-blue">Les comptes de réception</div>
              <div
                class="flex items-center"
                v-for="(item, index) in payment.accounts"
                :key="item.account_id"
              >
                <i
                  v-if="payment.accounts.length > 1"
                  @click="handleDeletePaymentItem(index)"
                  style="width: 5%"
                  class="mr-2 el-icon-circle-close text-gray-500 text-2xl cursor-pointer"
                ></i>
                <el-form-item
                  class="mr-2"
                  style="width: 50%"
                  :prop="`accounts.${index}.account_number`"
                  :rules="{
                    required: true,
                    message: 'Le compte est obligatoire',
                    trigger: 'change',
                  }"
                >
                  <el-select
                    v-model="item.account_number"
                    prop="account_number"
                    class="w-full"
                    @change="handleSelectAccount(item)"
                  >
                    <el-option
                      v-for="item in accounts"
                      :key="item.account_id"
                      :label="`${item.account_name}`"
                      :value="item.numero"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  style="width: 50%"
                  :prop="`accounts.${index}.amount`"
                  :rules="{
                    required: true,
                    message: 'Le montant est obligatoire',
                    trigger: 'blur',
                  }"
                >
                  <el-input-number
                    class="w-full"
                    :controls="false"
                    v-model="item.amount"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </div>
              <div class="flex justify-end">
                <el-button
                  v-if="payment.accounts.length < 2"
                  type="text"
                  @click="handleAccount"
                  >Ajouter un compte</el-button
                >
              </div>

              <div class="flex flex-row justify-end">
                <!-- <el-form-item label="Methode de paiement" prop="payment_mode">
                  <el-select
                    v-model="payment.payment_mode"
                    placeholder="Choisir la methode"
                    class="mr-2"
                  >
                    <el-option
                      v-for="item in modepayment"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item> -->
              </div>
            </div>
          </div>
        </el-form>
        <el-divider></el-divider>
        <div class="flex justify-end mb-2 pr-5">
          <h5 class="font-medium">Nombre : {{ invoices.length }} Facture(s)</h5>
        </div>
        <div class="customer-white">
          <el-table
            :data="invoices"
            style="width: 100%"
            v-loading="listLoading"
          >
            <el-table-column align="center" label="#" width="60">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="numero" label="DATE" width="180">
              <template slot-scope="{ row }">
                <span>{{ row.date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="due_date" label="ECHÉANCE" width="180">
              <template slot-scope="{ row }">
                {{ row.due_date }}
              </template>
            </el-table-column>
            <el-table-column prop="number" label="N° FACTURE" min-width="150">
              <template slot-scope="{ row }">
                <span class="text-blue">{{ row.number }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="amount_total"
              label="MONTANT TTC"
              width="150"
              align="right"
            >
              <template slot-scope="{ row }">
                <span>{{ row.total | moneyFilter }} {{row.currency}}</span>
              </template>
            </el-table-column>
            <el-table-column label="AVANCE" width="200" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.amount_to_pay | moneyFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="amount_due"
              label="RESTE À PAYER"
              width="180"
              align="right"
            >
              <template slot-scope="{ row }">
                <el-input-number
                  :controls="false"
                  :min="0"
                  size="medium"
                  :max="row.rest_to_pay"
                  v-model="row.amount"
                >
                </el-input-number>
              </template>
            </el-table-column>
            <el-table-column align="center" width="80">
              <template slot-scope="{ row }">
                <i
                  @click="handleRemoveItemInvoice(row._id)"
                  class="el-icon-circle-close text-red-500 text-2xl cursor-pointer mr-2"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAccounts } from "@/api/account";
import {
  getNextPaymentNO,
  getAllDocumentForContactOpen,
  newPaymentInvoice,
} from "@/api/sales2";
import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import SearchCustomer from "@/components/AddCustomer";
import Loading from "@components/Loanding/Full.vue";
import {mapGetters} from "vuex";
export default {
  name: "CreateInvoice",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: {Loading, SearchCustomer },
  data() {
    return {
      typeDoc: "INVOICE",
      labelPosition: "right",
      loadingCustomer: false,
      listLoading: false,
        loadingFull: false,
      customers: [],
      loadingCustomerInfo: false,
      accounts: [],
      accountBalance: 0,
      paymentNo: "",
      loading: false,
      modepayment: [],
      invoices: [],
      customerInfo: {
        contact_id: "",
        phone: "",
        email: "",
        first_name: "",
        last_name: "",
        street: "",
        contact_no: "",
        organization_name: "",
      },
      payment: {
        customer_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        type: "PAYMENT_CUSTOMER",
        invoices: [],
        accounts: [
          {
            account_number: "",
            account_name: "",
            amount: "",
          },
        ],
      },
      rules: {
        contact_id: [
          {
            required: true,
            message: "Veuillez selectionner le clinet",
            trigger: "change",
          },
        ],
        payment_no: [
          {
            required: true,
            message: "Le numéro du reçu est obligatoire",
            trigger: "blur",
          },
        ],

        payment_date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        account_number: [
          {
            required: true,
            message: "Le compte de reception est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
    totalInvoicePayment: function () {
      let total = 0;
      this.invoices.forEach((el) => {
        total += el.amount;
      });

      return total;
    },
    iscompleted: function () {
      if (this.invoices.length > 0 && this.invoices.length > 0) {
        return false;
      }
      return true;
    },
    totalPay: function () {
      return Math.floor(
        this.payment.accounts.reduce((total, item) => total + item.amount, 0)
      );
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    async handleCustomer(id) {
      this.resetPayment();
      this.listLoading = true;
      this.invoices = [];

      const customer = this.customers.find((c) => c.contact_id === id);
      this.customerInfo = Object.assign({}, customer);
      this.payment.contact_id = customer.contact_id;

      const accountContactIndex = this.accounts.findIndex(
        (el) => el.type_account === "CONTACT_CREDIT"
      );
      if (accountContactIndex > -1) {
        this.accounts.splice(accountContactIndex, 1);
      }
      if (
        customer.account_credit_number != null &&
        customer.account_credit_balance > 0
      ) {
        this.accounts.unshift({
          numero: customer.account_credit_number,
          account_name: customer.account_credit_title,
          type_account: "CONTACT_CREDIT",
          amount: 0,
        });
      }

      await getAllDocumentForContactOpen(id, "INVOICE")
        .then((res) => {
          setTimeout(() => {
            const items = res.data;
            items.map((inv) => {
              this.$set(inv, "amount", inv.total - inv.amount_to_pay);
              this.$set(inv, "rest_to_pay", inv.total - inv.amount_to_pay);
              this.invoices.push(inv);
            });

            const accountDefault = this.accounts.find(
              (el) => el.has_primary === true
            );
            console.log(this.payment.accounts[0]);

            this.payment.accounts[0].account_number = accountDefault.numero;
            this.payment.accounts[0].account_name = accountDefault.account_name;
            this.payment.accounts[0].amount = this.totalInvoicePayment;
            this.listLoading = false;
          }, 200);
        })
        .catch((err) => {
          this.$message({
            message: err.data.response,
            type: "warning",
          });
        });
    },
    resetPayment() {
      this.payment = {
        contact_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no:"",
        type: "PAYMENT_CUSTOMER",
        invoices: [],
        accounts: [
          {
            account_number: "",
            account_name: "",
            amount: "",
          },
        ],
      };
    },
    handleSelectAccount(item) {
      const accountExistList = this.payment.accounts.filter(
        (el) => el.account_number === item.account_number
      ).length;

      if (accountExistList > 1) {
        item.account_number = "";
        this.$message({
          message: "Veuillez selectionner un autre compte",
          type: "warning",
        });
      } else {
        const account = this.accounts.find(
          (el) => el.numero === item.account_number
        );
        item.account_name = account.account_name;
      }
    },
    handleSelectedCustomer(id) {
      // selectionner le contact
      this.resetPayment();
      this.listLoading = true;
      this.invoices = [];

      const customer = this.customers.find((c) => c.contact_id === id);
      this.customerInfo = Object.assign({}, customer);
      this.payment.contact_id = customer.contact_id;

      const accountContactIndex = this.accounts.findIndex(
        (el) => el.type_account === "CONTACT_CREDIT"
      );

      if (accountContactIndex > -1) {
        this.accounts.splice(accountContactIndex, 1);
      }

      if (
        customer.account_credit_number != null &&
        customer.account_credit_balance > 0
      ) {
        this.accounts.unshift({
          numero: customer.account_credit_number,
          account_name: customer.account_credit_title,
          type_account: "CONTACT_CREDIT",
          amount: 0,
        });
      }
    },
    handleSearchCustomer(data) {
      this.customers = data;
    },
    handleDeletePaymentItem(index) {
      this.payment.accounts.splice(index, 1);
    },
    handleAccount() {
      this.payment.accounts.push({
        account_number: "",
        account_name: "",
        amount: 0,
      });
    },

    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getNextPaymentNo() {
      await getNextPaymentNO()
        .then((res) => {
          this.paymentNo = res.data.number;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initData() {
        this.loadingFull = true;
        setTimeout(() =>{
          const customer = this.$route.params.customer;
          if (customer) {
                this.customerInfo = {...customer};
                this.customers.push( {
                    ...customer
                })
                this.handleCustomer(this.customerInfo.contact_id)
          }
            this.getNextPaymentNo();
            this.getAccounts();
            this.loadingFull = false;
        },1.5*1000)

    },
    createData(paymentForm) {
      this.$refs[paymentForm].validate((valid) => {
        if (valid) {
          const exitAmountZero = this.payment.accounts.filter(
            (el) => el.amount === 0
          ).length;

          if (this.totalPay !== this.totalInvoicePayment) {
            this.$message({
              showClose: true,
              message:
                "Les montant total des factures est different des comptes",
              type: "error",
            });
          } else if (exitAmountZero > 0) {
            this.$message({
              showClose: true,
              message: "Le montant ne peut être égal 0",
              type: "error",
            });
          } else {
            this.loading = true;
            this.invoices.forEach((inv) => {
              this.payment.invoices.push({ _id: inv._id, amount: inv.amount });
            });

            this.payment.payment_no = this.paymentNo;
            newPaymentInvoice(this.payment, this.typeDoc)
              .then(() => {
                setTimeout(() => {
                  this.$router.push({
                    path: "/sales/index?tab=PAYMENT",
                    query: { newPayment: true },
                  });
                  this.loading = false;
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  if (err.status === 400) {
                    this.$message({
                      message: "Veuillez remplir les champs obligatoire.",
                      type: "warning",
                    });
                  }

                  if (err.status === 409) {
                    this.$message({
                      message: err.data.message,
                      type: "warning",
                    });
                  }
                  this.loading = false;
                }, 1.5 * 1000);
              });
          }
        } else {
          console.log("hello");
        }
      });
    },
    handleChange(id) {
      const account = this.accounts.find((el) => el.numero === id);
      this.payment.account_name = account.account_name;
      this.accountBalance = account.balance;
    },

    handleBackPage() {
      this.$router.push({
        path: "/sales/invoice?tab=PAYMENT",
      });
    },

    handleRemoveItemInvoice(id) {
      const index = this.invoices.findIndex((el) => el._id === id);
      this.invoices.splice(index, 1);
      this.payment.accounts[0].amount = this.totalInvoicePayment;
    },
  },
};
</script>

<style></style>
