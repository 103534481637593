import request from "@/utils/request";

export function getDocumentSaleNo(type) {
  return request({
    url: `/documents-sale/next-number/${type}`,
    method: "get",
  });
}

// gestion des facture
export function newDocument(data, type) {
  return request({
    url: `/documents-sale/${type}`,
    method: "post",
    data,
  });
}

export function updateDocument(data, type, documentId) {
  return request({
    url: `/documents-sale/${type}/${documentId}`,
    method: "put",
    data,
  });
}

export function copyDocument(invoiceId, type) {
  return request({
    url: `/documents-sale/${type}/copy/${invoiceId}`,
    method: "put",
  });
}

export function validDocument(invoiceId, type) {
  return request({
    url: `/documents-sale/${type}/valid/${invoiceId}`,
    method: "put",
  });
}

export function cancelDocument(invoiceId, type) {
  return request({
    url: `/documents-sale/${type}/cancel/${invoiceId}`,
    method: "put",
  });
}
export function closeDocument(invoiceId, type) {
  return request({
    url: `/documents-sale/${type}/close/${invoiceId}`,
    method: "put",
  });
}

export function getDocument(numero, type) {
  return request({
    url: `/documents-sale/${type}/${numero}`,
    method: "get",
  });
}



export function getAllDocument(query, type) {
  return request({
    url: `/documents-sale/${type}/async/fetch`,
    method: "get",
    params: query,
  });
}

export function getAllDocumentForContact(query, contactId, type) {
  return request({
    url: `/documents-sale/${type}/fetch/async/contact/${contactId}`,
    method: "get",
    params: query,
  });
}

export function getAllDocumentForContactOpen(contactId, type) {
  return request({
    url: `/documents-sale/${type}/contact/open/${contactId}`,
    method: "get",
  });
}

export function getAllDocumentReference(reference, type) {
  return request({
    url: `/documents-sale/${type}/reference/${reference}`,
    method: "get",
  });
}



export function fetchStatistcs(type) {
  return request({
    url: `/documents-sale/${type}/statistics`,
    method: "get",
  });
}

// paiement des factures
export function getNextPaymentNO() {
  return request({
    url: "/documents-sale/payments/next-number",
    method: "get",
  });
}

export function getNextRefundNO() {
  return request({
    url: "/documents-sale/payments/refund-number",
    method: "get",
  });
}

export function newPaymentInvoice(data,type) {
  return request({
    url: `/documents-sale/payments/${type}`,
    method: "post",
    data,
  });
}

export function fetchPaymentInvoice(query) {
  return request({
    url: "/documents-sale/payments",
    method: "get",
    params: query,
  });
}

export function getPaymentInvoice(numero) {
  return request({
    url: `/documents-sale/payments/number/${numero}`,
    method: "get",
  });
}

export function getPaymentForInvoice(id, query) {
  return request({
    url: `/documents-sale/payments/${id}`,
    method: "get",
    params : query
  });
}

export function cancelPaymentInvoice(number) {
  return request({
    url: `/documents-sale/payments/cancel/number/${number}`,
    method: "delete",
  });
}

export function getAllPaymentInvoice(query) {
  return request({
    url: "/payment-invoice/fetch/async",
    method: "get",
    params: query,
  });
}


export function applyCreditToInvoice(data) {
  return request({
    url: `/documents-sale/payments/apply-credit-to-invoice`,
    method: "post",
    data
  });
}


// gestion des devis

export function converEstimateToInvoice(invoiceId) {
  return request({
    url: `/documents-sale/estimate/convert-to-invoice/${invoiceId}`,
    method: "put",
  });
}

// gestion des orders

export function converOrderToInvoice(data, invoiceId) {
  return request({
    url: `/documents-sale/order-to-invoice/${invoiceId}`,
    method: "post",
    data
  });
}

// rapport de ventes
export function fetchRapportResume(query) {
  return request({
    url: "/documents-sale/rapport/resume",
    method: "get",
    params: query,
  });
}

export function fetchRapportSalesArticle(query) {
  return request({
    url: `/documents-sale/rapport/articles`,
    method: "get",
    params: query,
  });
}

export function fetchRapportSalesCustomer(query) {
  return request({
    url: `/documents-sale/rapport/customers`,
    method: "get",
    params: query,
  });
}


export function fetchRapportCHART(query, type) {
  return request({
    url: `/rapport-sales/chart/${type}`,
    method: "get",
    params: query,
  });
}

export function fetchRapportCHARTSale(query) {
  return request({
    url: `/rapport-sales/chart-sales`,
    method: "get",
    params: query,
  });
}

export function fetchStatSales() {
  return request({
    url: `/documents-sale/rapport/sales/open`,
    method: "get",
  });
}

// Gestion des caisses enregistrieuse

export function getAllPos(query) {
  return request({
    url: `/point-of-sales/fetch`,
    method: "get",
    params: query,
  });
}

export function enablePos(id) {
  return request({
    url: `/point-of-sales/enabled/${id}`,
    method: "put",
  });
}

export function createPos(data) {
  return request({
    url: `/point-of-sales`,
    method: "post",
    data
  });
}



export function updatePos(data, number) {
  return request({
    url: `/point-of-sales/${number}`,
    method: "put",
    data
  });
}

export function fetchPos(number) {
  return request({
    url: `/point-of-sales/${number}`,
    method: "get",
  });
}

// fiche de caisse
export function fetchFichePos(query) {
  return request({
    url: `/point-of-sales/fiches`,
    method: "get",
    params: query
  });
}

export function fetchRapportFiche(numberFiche) {
  return request({
    url: `/point-of-sales/rapport/${numberFiche}`,
    method: "get",
  });
}

export function openPos(data) {
  return request({
    url: `/point-of-sales/open`,
    method: "post",
    data
  });
}


export function closePos(data) {
  return request({
    url: `/point-of-sales/close`,
    method: "put",
    data
  });
}

export function getMyPOSOpen() {
  return request({
    url: `/point-of-sales/mypos-open`,
    method: "get",
  });
}

// ticket

export function createTicket(data) {
  return request({
    url: `/point-of-sales/ticket/create`,
    method: "post",
    data
  });
}
export function cancelTicket(number) {
  return request({
    url: `/point-of-sales/ticket/canceled/${number}`,
    method: "put",
  });
}

export function getListTicket(query, number) {
  return request({
    url: `/point-of-sales/ticket/list/${number}`,
    method: "get",
    params: query
  });
}

export function  fetchRapportRevenue(year){
  return request({
    url: `/documents-sale/rapport/revenue/${year}`,
    method: "get",
  });
}