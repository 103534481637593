<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="loading">
    <Loading />
  </div>
</template>

<script>
import Loading from "./index";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      fullscreenLoading: false,
    };
  },
  methods: {
    openFullScreen2() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
      setTimeout(() => {
        loading.close();
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.9), rgba(0, 0, 0, 0.9));
  opacity: 0.3;
  /* background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.5),
    rgba(0, 0, 0, 0.5)
  ); */
  background: -webkit-radial-gradient(
    rgba(25, 25, 25, 0.9),
    rgba(0, 0, 0, 0.9)
  );
}

loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
</style>
