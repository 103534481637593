import request from "@/utils/request";

export function addContact(data) {
  return request({
    url: "/contacts",
    method: "post",
    data,
  });
}

export function activeContact(numero) {
  return request({
    url: `/contacts/active/${numero}`,
    method: "put",
  });
}

export function updateContact(data, contactId) {
  return request({
    url: `/contacts/${contactId}`,
    method: "put",
    data,
  });
}

export function getContact(contactId) {
  return request({
    url: `/contacts/async/${contactId}`,
    method: "get",
  });
}

export function fetchCustomers(query) {
  return request({
    url: `/contacts/fetch/async/customer`,
    method: "get",
    params: query,
  });
}

export function fetchSuppliers(query) {
  return request({
    url: `/contacts/fetch/async/supplier`,
    method: "get",
    params: query,
  });
}

export function getTypes() {
  return request({
    url: `/contacts/types`,
    method: "get",
  });
}

export function getStatistique() {
  return request({
    url: `/contacts/statistique`,
    method: "get",
  });
}

export function getStats(id) {
  return request({
    url: `/contacts/stats/${id}`,
    method: "get",
  });
}
// search

export function searchCustomer(query) {
  return request({
    url: `/contacts/search-customer`,
    method: "get",
    params: query,
  });
}

export function searchSupplier(query) {
  return request({
    url: `/contacts/search-supplier`,
    method: "get",
    params: query,
  });
}
